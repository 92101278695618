<template>
  <div class="content" id="send_card_area">
    <e-breadcrumbs />
    <h1 class="page_title">{{ $t('menu.MENU09.141') }}</h1>
    <div class="content_box mt10">
        <p align="left"><b><font face="맑은고딕" size="4" color="#004080">카드보내기에 필요한 정보들을 입력해주세요.</font></b></p><br>
        <font color="#CD4275">* 다중전송을 하실경우에 공백없이 메일주소 뒤에 콤마( , ) 를 붙여주세요.</font><br>
        <font color="#CD4275">* Hotmail.com 또는 한글이 지원되지 않는 해외로 메일을 전송하실경우 "보낸 이(이름)"을 영문으로 써주세요.</font><br>
        <font color="#004080">* 메일 주소 기재시 소문자로 적어주세요. (예) .com .net</font>
        <table class="tbl_row">
            <colgroup>
              <col width="130"><col>
              <col width="130"><col>
            </colgroup>
            <tbody>
            <tr>
                <th>보낸 이 (이름)</th>
                <td><input type="text" id="fromName" style="width:100%;" v-model="params.fromName" @input="inputTextChk('fromName')"/></td>
                <th>보낸 이 E-mail</th>
                <td><input type="text" id="fromEmail"  style="width:100%;" v-model="params.fromEmail" @input="inputTextChk('fromEmail')"/></td>
            </tr>
            <tr>
                <th>받을 이 (이름)</th>
                <td><input type="text" id="toName" style="width:100%;" v-model="params.toName"  @input="inputTextChk('toName')"/></td>
                <th>받을 이 E-mail</th>
                <td><input type="text" id="toEmail" style="width:100%;" v-model="params.toEmail" @input="inputTextChk('toEmail')"/></td>
            </tr>
            <tr>
                <th>메일제목</th>
                <td colspan="3"><input type="text" id="toTitle" style="width:100%;" v-model="params.toTitle" @input="inputTextChk('toTitle')"/></td>
            </tr>
            </tbody>
        </table>
        <table class="tbl_row">
            <col aria-colspan="4">
            <tbody>
            <tr aria-colspan="4">
                <th style="text-align:center">전달할 글내용</th>
            </tr>
            <tr aria-colspan="4">
                <td><textarea style="width:100%; height:400px;" id="reqCont" v-model="params.reqCont" @input="inputTextChk('reqCont')"/></td>
            </tr>
            </tbody>
        </table>
        <div class="mt10 flex_box">
        <span class="ml_auto">
            <a class="button blue lg ml5" @click="samplePop()">샘플 문구보기</a>
            <a class="button blue lg ml5" @click="goNextStep()">다음</a>
        </span>
        </div>
    </div>
    <win-layer-pop>
      <component
        :is="customComponent"
        v-if="customComponent"
        @close="closePopup"
      />
    </win-layer-pop>
  </div>
</template>
<script>
import { rootComputed } from '@/store/helpers'
import auth from '@/api/services/auth'

export default {
    name: 'sendCard',
    components: {
        'sample-pop': () => import('@/pages/common/popup/sendCardSamplePop'),
        'win-layer-pop': () => import('@/pages/trans/popup/WinLayerPop')
    },
    computed: {
    ...rootComputed
    },
    props: {
        cardCd: {
        type: String,
        default: null
        }
    },
    data () {
     return {
        customComponent: null,
        params: {
            fromName: '',
            fromEmail: '',
            toName: '',
            toEmail: '',
            toTitle: '',
            cardCd: '',
            reqCont: ''
        }
     }
    },
    created () {
        this.init()
    },
    methods: {
        async init () {
            await auth.info(this.auth.jwt).then((res) => {
              const result = res.data
              this.params.fromName = result.userName
              this.params.fromEmail = result.userEmail
            })
            this.params.cardCd = this.cardCd
        },
        samplePop () {
            this.customComponent = 'sample-pop'
            this.$ekmtcCommon.layerOpen('#send_card_area > .popup_dim')
        },
        closePopup (obj) {
            this.customComponent = null
            this.$ekmtcCommon.layerClose('#send_card_area > .popup_dim')
        },
        async goNextStep () {
          let arrOne = ['fromName', 'fromEmail', 'toName', 'toEmail', 'toTitle', 'reqCont']
          let arrEmail = ['fromEmail', 'toEmail']
          let isOk = true
          await arrOne.forEach(key => {
            if (!this.checkValidation(key)) {
                isOk = false
            }
          })
        //   await arrEmail.forEach(key => {
        //     if (!this.inputEmailChk(key)) {
        //         isOk = false
        //     }
        //   })
          if (!isOk) {
            return
          }

          this.$router.push({ name: 'sendNewYearCardLastStep', params: { regParams: this.params } }).catch(() => {})
        },
        goPrecedingStep () {
            this.$router.go(-1)
        },
        checkValidation (id) {
            const selector = document.querySelector('#' + id)
            let isOk = true

            this.$ekmtcCommon.hideErrorTooltip(selector)

            switch (id) {
                case 'fromName':
                if (this.$ekmtcCommon.isEmpty(selector.value)) {
                    this.$ekmtcCommon.showErrorTooltip(selector, '보낸 이 (이름) 을 입력하세요.')
                    isOk = false
                }
                if (!this.$ekmtcCommon.checkTextByte(selector.value, 200)) {
                    this.$ekmtcCommon.showErrorTooltip(selector, '입력한 내용이 너무 많습니다.')
                     isOk = false
                }
                break
                case 'fromEmail':
                if (this.$ekmtcCommon.isEmpty(selector.value)) {
                    this.$ekmtcCommon.showErrorTooltip(selector, '보낸 이 E-mail 을 입력하세요e.')
                    isOk = false
                }
                if (!this.$ekmtcCommon.checkTextByte(selector.value, 200)) {
                    this.$ekmtcCommon.showErrorTooltip(selector, '입력한 내용이 너무 많습니다.')
                     isOk = false
                }
                break
                case 'toName':
                if (this.$ekmtcCommon.isEmpty(selector.value)) {
                    this.$ekmtcCommon.showErrorTooltip(selector, '받을 이 (이름) 을 입력하세요')
                    isOk = false
                }
                if (!this.$ekmtcCommon.checkTextByte(selector.value, 200)) {
                    this.$ekmtcCommon.showErrorTooltip(selector, '입력한 내용이 너무 많습니다.')
                     isOk = false
                }
                break
                case 'toEmail':
                if (this.$ekmtcCommon.isEmpty(selector.value)) {
                    this.$ekmtcCommon.showErrorTooltip(selector, '받을 이 E-mail을 입력하세요')
                    isOk = false
                }
                if (!this.$ekmtcCommon.checkTextByte(selector.value, 2000)) {
                    this.$ekmtcCommon.showErrorTooltip(selector, '입력한 내용이 너무 많습니다.')
                    isOk = false
                }
                break
                case 'toTitle':
                if (this.$ekmtcCommon.isEmpty(selector.value)) {
                    this.$ekmtcCommon.showErrorTooltip(selector, '메일제목을 입력하세요')
                    isOk = false
                }
                if (!this.$ekmtcCommon.checkTextByte(selector.value, 200)) {
                    this.$ekmtcCommon.showErrorTooltip(selector, '입력한 내용이 너무 많습니다.')
                     isOk = false
                }
                break
                case 'reqCont':
                if (this.$ekmtcCommon.isEmpty(selector.value)) {
                    this.$ekmtcCommon.showErrorTooltip(selector, '전달할 글내용을 입력하세요')
                    isOk = false
                }
                if (!this.$ekmtcCommon.checkTextByte(selector.value, 3000)) {
                    this.$ekmtcCommon.showErrorTooltip(selector, '입력한 내용이 너무 많습니다.')
                     isOk = false
                }
                break
            }
            return isOk
        },
        inputTextChk (id) {
            const selector = document.querySelector('#' + id)
            if (this.$ekmtcCommon.isNotEmpty(selector.value)) {
               this.$ekmtcCommon.hideErrorTooltip(selector)
            }
        },
        inputEmailChk (id) {
            const selector = document.querySelector('#' + id)
            let isOk = true
            this.$ekmtcCommon.hideErrorTooltip(selector)
            if (selector.value.indexOf(',') > -1) {
                if (!this.$ekmtcCommon.multiCheckEmail(selector.value, ',')) {
                    isOk = false
                }
            } else {
                if (!this.$ekmtcCommon.checkEmail(selector.value)) {
                    isOk = false
                }
                if (!isOk) {
                    this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.VOSD100.043')) // 이메일 체크
                }
            }
            return isOk
        }
    }
}
</script>
